import { t } from "../../../services/translation/TranslationUtils";

export const tableHeaders = [
    { name: 'Development string', width: 40 },
    { name: 'Translation string', width: 40 },
];

export const tableValues = [
    'original_string',
    'translated_string',
];

export const manageState = {
    filters: {
        language_id: JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}')?.language,
        site_key: 'backend',
        search: ""
    },
    popup: {
        editpopup: {
            string_id: null,
            showpopup: false,
            newstring: ""
        }
    },
    options: {} as any,
    data: [] as any,
    manage: true,
    initialCall: true,
    current_page: 1,
    limit: 10,
    status: null,
    totalPages: 0,
    offset: 0,
    totalCount: 0
};

export const filterValues = (options: any) => {
    let row = [
        {
            name: 'language_id',
            type: 'single-select-without-label',
            placeholder: 'Select language',
            value: [],
            options: options?.AppLang,
        },
        {
            name: 'site_key',
            type: 'single-select-without-label',
            placeholder: 'Select site',
            value: [],
            options: [
                { value: 'frontend', label: "Frontend" },
                { value: 'backend', label: "Backend" },
            ],
        },
        {
            name: 'search',
            class: {
                mainclass: 'h-100',
                feildclass: 'h-100'
            },
            type: 'text',
            placeholder: 'Search development string',
            value: "",
            label: true
        },
    ]
    return row;
}
