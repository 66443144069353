import React from 'react';
import FileReader from '../atoms/FileReader';
import { t } from '../../services/translation/TranslationUtils';
import Button from '../atoms/Button';
import CommonServices from '../../services/CommonService';
import { Delete } from '../../utils/icons/Delete';

interface Props {
    fileData: any;
    onDeleteFile?: (value: any) => void;
    type?: string;
}

const ViewPreviewFile: React.FC<Props> = ({ fileData, onDeleteFile, type }) => {
    const fileNames = Array.isArray(fileData.file_name) ? fileData.file_name : [fileData.file_name];
    const filePaths = Array.isArray(fileData.url) ? fileData.url : [fileData.url];

    return (
        <div>
            {fileNames.length > 0 && (
                <div>
                    {type && <div>{CommonServices?.capitalizeLabel(type ?? "") + ': '}</div>}
                    {fileNames.map((fileName: string, index: number) => (
                        <div key={index}>
                            <a href={filePaths[index]} target="_blank" rel="noopener noreferrer">
                                {fileName}
                            </a>
                            {onDeleteFile &&
                                <span className='px-2'
                                    onClick={() => onDeleteFile(filePaths[index])}
                                ><Delete /></span>
                            }
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ViewPreviewFile;
