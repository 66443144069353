import React, { useEffect, useState } from "react";
import TitleAtom from "../../common/TitleAtom";
import Button from "../../atoms/Button";
import { t } from "../../../services/translation/TranslationUtils";
import Popup from "../../molecules/Popup";
import { APICALL } from "../../../services/ApiServices";
import { FAQ_CATEGORY } from "../../../routes/ApiEndpoints";
import { useNavigate } from "react-router-dom";
import PopupForm, { FormField } from "../../molecules/PopupForm";
import {
  getManageFields,
  INITIAL_MANAGE_FAQ,
  ManageFaqInterface,
  manageState,
  tableHeaders,
  tableValues,
} from "./Interface";
import TableStructure from "../../atoms/TableStructure";
import ManageUserTabs from "../../organisms/users/manage/ManageUserTabs";
import Pagination from "../../molecules/Paginations";
import CustomNotify from "../../atoms/CustomNotify";
import FormValidation from "../../../services/formvalidation/Validation";
import ManageFaqFilters from "./ManageFaqFilters";

const ManageFaqOrganism: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<ManageFaqInterface>(INITIAL_MANAGE_FAQ);
  const [state1, setState1] = useState(manageState);

  useEffect(() => {
    fetchData(manageState.postData);
  }, []);
  const handleOpenPopup = (faqCategory: any) =>
    setState((prevState) => ({
      ...prevState,
      isPopupOpen: true,
      formData: { id: faqCategory.id, name: faqCategory.title },
    }));

  const handleClosePopup = () => {
    setState((prevState) => ({
      ...prevState,
      isPopupOpen: false,
      formData: { id: null, name: "" },
      error: { name: "" },
    }));
  };
  const fetchData = async (postData: any) => {
    const response = await APICALL.service(
      FAQ_CATEGORY +
        `?status=${postData.status}&page=${postData.current_page}&q=${postData.search}&order_type=${postData.order_type}&order=${postData.order}`,
      "GET"
    );
    console.log("response of faqs", response);
    if (response.status === 200) {
      setState1((prevState: any) => ({
        ...prevState,
        postData: {
          ...prevState.postData,
          total_pages: response.data.total_pages,
        },
        data: response.data.data,
      }));
    }
  };

  const handleFilter = (q: string) => {
    setState1((prevState: any) => ({
      ...prevState,
      postData: {
        ...prevState.postData,
        search: q,
      },
    }));
  };

  const handlePaginationClick = (pageNumber: number) => {
    let postData = {
      ...state1.postData,
      current_page: pageNumber,
    };
    setState1((prevState: any) => ({
      ...prevState,
      postData: postData,
    }));
    fetchData(postData);
  };

  const handleClear = () => {
    setState1((prevState: any) => ({
      ...prevState,
      postData: manageState.postData,
    }));
    fetchData(manageState.postData);
  };

  const handleSort = () => {
    let value = state1.postData.order === "DESC" ? "ASC" : "DESC";
    let postData = {
      ...state1.postData,
      order_type: "created_at",
      order: value,
    };
    setState1((prevState: any) => ({
      ...prevState,
      postData: postData,
    }));
    fetchData(postData);
  };

  const handleTabsClick = (tab: string) => {
    let status = null;
    if (tab !== "all") {
      status = tab === "active";
    }
    let postData = {
      ...state1.postData,
      status: status,
    };
    setState1((prevState: any) => ({
      ...prevState,
      postData: postData,
    }));
    fetchData(postData);
  };

  const handleArchivePopup = (value: any) => {
    setState1((prevState: any) => ({
      ...prevState,
      popup: value,
    }));
  };

  const handleArchive = async (value: any) => {
    let postData = {
      status: !value.activity,
      status_change: true,
    };
    const response = await APICALL.service(
      FAQ_CATEGORY + "/" + value.id,
      "DELETE",
      postData
    );

    if (response.status === 200) {
      setState1((prevState: any) => ({
        ...prevState,
        popup: false,
      }));
      fetchData(state1.postData);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    setState((prevState) => ({
      ...prevState,
      formData: { ...prevState?.formData, [name]: value },
      error: { ...prevState.error, name: "" },
    }));
  };

  const handleSave = async () => {
    const { id, name } = state.formData;
    const title = name?.trim();

    const errors: Record<string, string> = {};
    const formData: any = state.formData;
    const tabFields = getManageFields(state);
    tabFields.forEach((fieldGroup: any) => {
      Object.values(fieldGroup).forEach((field: any) => {
        if (field.required) {
          errors[field.name] = FormValidation?.nameValidation(
            formData[field?.name] ?? ""
          );
        }
      });
    });
    const hasErrors = Object.values(errors).some((error) => error?.length > 0);
    if (hasErrors) {
      setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          ...errors,
        },
      }));
      return;
    }
    try {
      let response;
      if (id) {
        response = await APICALL.service(`${FAQ_CATEGORY}/${id}`, "PUT", {
          title,
          status: true,
        });
      } else {
        response = await APICALL.service(FAQ_CATEGORY, "POST", {
          title,
          status: true,
        });
      }
      if (response?.status === 200) {
        CustomNotify({
          type: "success",
          message: t(response?.message || "Operation successful."),
        });
        fetchData(state1.postData);
        handleClosePopup();
      } else if (response?.status === 400) {
        setState((prevState) => ({
          ...prevState,
          error: {
            name:
              response?.message || "A category with this title already exists.",
          },
        }));
      } else {
        console.error("Failed to save FAQ category:", response?.message);
      }
    } catch (error) {
      console.error("Error saving FAQ category:", error);
      CustomNotify({
        type: "error",
        message: t("An error occurred while saving the FAQ category."),
      });
    }
  };

  const handleEdit = (data: { id: number }) => {
    const faqCategoryTitle = state1.data.find((faq) => faq.id === data.id);
    if (faqCategoryTitle) handleOpenPopup(faqCategoryTitle);
  };
  const handleCardClick = (data: { id: number }) => {
    navigate(`/edit-faq/${data.id}`);
  };

  const handleSearch = () => {
    let postData = {
      ...state1.postData,
      current_page:1
    }
    setState((prevState: any) => ({
      ...prevState,
      postData:postData
    }));
    fetchData(postData)
  }

  const popupFormFields: FormField[] = [
    {
      label: "Title",
      name: "name",
      value: state.formData.name,
      type: "text",
      handleChange: handleInputChange,
      error: state.error?.name,
    },
  ];
  console.log("data", state1.data);
  return (
    <>
      {/* Header Section */}
      <div className="search-bar py-3">
        <div className="row">
          <TitleAtom title={t("Manage FAQ")} />
          <div className="col-6">
            <Button
              className="create_button px-3 float-end"
              title={"+ " + t("Create FAQ Category")}
              handleClick={handleOpenPopup}
            />
          </div>
        </div>
      </div>
      <ManageFaqFilters
        value={state1.postData.search}
        handleFilter={handleFilter}
        handleSearch={handleSearch}
        handleClear={handleClear}
      />
      <ManageUserTabs
        handleClick={handleTabsClick}
        status={state1?.postData?.status}
      />

      <TableStructure
        isAction
        data={state1.data}
        headers={tableHeaders}
        values={tableValues}
        handleEdit={(id) => handleEdit(id)}
        handleArchive={handleArchivePopup}
        handleView={handleCardClick}
        handleSort={handleSort}
      />

      {state1?.postData?.total_pages > 1 && (
        <Pagination
          currentPage={state1?.postData?.current_page}
          totalPages={state1?.postData?.total_pages}
          handlePaginationClick={handlePaginationClick}
        />
      )}

      {/* Popup */}
      {state?.isPopupOpen && (
        <Popup
          title={
            state1?.data[0]?.id
              ? t("Edit FAQ Category")
              : t("Create FAQ Category")
          }
          body={<PopupForm fields={popupFormFields} />}
          // cancel={() => togglePopup(false)}
          close={handleClosePopup}
          submit={handleSave}
          cancel={handleClosePopup}
          yestext={t("Save")}
          notext={t("Back")}
        />
      )}

      {state1?.popup && (
        <Popup
          body={t("Are you sure, you want to change the activity") + "?"}
          yestext={t("Yes")}
          notext={t("No")}
          submit={() => handleArchive(state1.popup)}
          cancel={() =>
            setState1((prevState: any) => ({
              ...prevState,
              popup: false,
            }))
          }
        />
      )}
    </>
  );
};

export default ManageFaqOrganism;
