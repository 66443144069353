import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LabelField from "../atoms/LabelField";
import ErrorMessage from "../atoms/ErrorMessage";
interface EditorComponentProps {
  data: string;
  name: string;
  error?: string;
  mandatory?: boolean;
  maindivclass?: string;
  // toolbarItems?: string[];
  onReady?: (editor: any) => void;
  onChange?: (data: string, field: string) => void;
  onBlur?: (event: any, editor: any) => void;
  onFocus?: (event: any, editor: any) => void;
  isLabel?: boolean;
}

const EditorComponent: React.FC<EditorComponentProps> = ({
  mandatory = false,
  name = "",
  error = "",
  data,
  maindivclass = "",
  onReady,
  onChange,
  onBlur,
  onFocus,
  isLabel = true,
}) => {
  const config = {
    toolbar: {
      items: [
        "heading",
        "fontFamily",
        "fontSize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subScript",
        "superScript",
        "|",
        "highlight",
        "link",
        "|",
        "blockQuote",
        "alignment",
        "|",
        "outdent",
        "indent",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
      ],
    },
    link: {
      defaultProtocol: "https://",
    },
  };

  return (
    <div className={maindivclass}>
      {isLabel && <LabelField title={name} mandatory={mandatory} />}{" "}
      <div className={error ? "border-danger" : ""}>
        <CKEditor
          editor={ClassicEditor}
          data={data}
          config={config}
          onReady={onReady}
          onChange={(event: any, editor: any) => {
            const editorData = editor.getData();
            onChange && onChange(editorData, name);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
      <ErrorMessage errorMessage={error} />{" "}
    </div>
  );
};

export default EditorComponent;
