import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ImageField from "../atoms/ImageField";
import { useSelector, useDispatch } from "react-redux";
import { t } from "../../services/translation/TranslationUtils";
import { addUserDetails, removeUserDetails, selectAuth } from "../../features/auth/AuthSlice";
import Button from "../atoms/Button";
import { APICALL } from "../../services/ApiServices";
import { Logout, manageTranslation } from "../../routes/ApiEndpoints";
import Logouticon from "../../utils/icons/Logouticon";
import MultiSelect from "../atoms/MultiSelectField";
import { Option } from "../common/CommonInterfaces";
import CustomNotify from "../atoms/CustomNotify";

const Header: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOutClick = async () => {
    const response = await APICALL.service(Logout, 'GET');
    if (response.status === 200) {
      dispatch(removeUserDetails());
      localStorage.clear();
      navigate("/login");
    } else {
      CustomNotify({type: 'error', message: t("Error while logging out")})
    }
  };

  const handleLanguageChange = async (e: any) => {
    let postdata = {
      language_id: e?.value,
      site_key: 'backend'
    }
    try {
      dispatch(addUserDetails({ language: e?.value }));
      const response = await APICALL.service(manageTranslation, 'POST', postdata);
      if (response?.status === 200) {
        localStorage.setItem('translations', JSON.stringify(response?.data));
        window?.location?.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row" style={{ backgroundColor: "#e8f8fb" }}>
      <div className="col-md-12">
          <nav className="navbar navbar-expand-lg d-md-block d-lg-flex">
            <div className="border-sm-top d-flex justify-content-between">
              <Link className="navbar-brand mx-0" to="/">
                <ImageField
                  altText="Logo"
                  className=""
                  value="/static/images/logo.svg"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>


            <div
              className="collapse navbar-collapse justify-content-end me-3"
              id="navbarNav"
            >
              <ul
                className={`navbar-nav align-items-lg-center`}
              >
                <li className="mx-2">
                  {userAuth && userAuth ? (
                    <>
                      <MultiSelect
                        name="language"
                        standards={userAuth?.languageOptions?.find((option: Option) => option?.value == userAuth?.language)}
                        options={userAuth?.languageOptions}
                        handleChange={handleLanguageChange}
                        isClearable={false}
                      />
                    </>) : null
                  }
                </li>
                <li className="">
                  {userAuth && userAuth ? (
                    <Link
                      to={'/employee/profile'}
                      className="btn mt-0 px-3 form-button"
                    >
                      {t("Profile")}
                    </Link>
                  ) : null}
                </li>
                <li className="pe-0 me-0 logout-btn">
                  {userAuth && userAuth ? (
                    <Button
                      className="btn btn-link px-0 text-decoration-none"
                      handleClick={logOutClick}
                    >
                      <div className="d-block d-md-block d-lg-none mt-3">
                        <img src={''} alt="Logout icon" title={t("Logout")} />
                        <Logouticon />
                        <span className="color-dark-pink text-decoration-none ms-3 text-uppercase">
                          {t("Logout")}
                        </span>
                      </div>
                      <div className="d-none d-md-none d-lg-block">
                        <span title={t("Logout")}><Logouticon /></span>
                      </div>
                    </Button>
                  ) : (
                    <Link
                      to="/login"
                      className="btn mt-5 rounded-0 px-3form-button"
                    >
                      {"Login"}
                    </Link>
                  )}
                </li>
              </ul>
            </div>
            
          </nav>
      </div>
    </div>
  );
};

export default Header;
