import React, { ChangeEvent, useEffect, useState } from "react";
import MyProfileForm from "./MyProfileForm";
import { initialProfileState, profileOptions } from "../State";
import { APICALL } from "../../../../services/ApiServices";
import {
  createUser,
  manageUser,
  fileData,
} from "../../../../routes/ApiEndpoints";
import CustomNotify from "../../../atoms/CustomNotify";
import { t } from "../../../../services/translation/TranslationUtils";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../../features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import { Files } from "../../../common/CommonInterfaces";

const MyProfileOrganism: React.FC = () => {
  const [state, setState] = useState(initialProfileState);
  const user = useSelector(selectAuth);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let postdata = {
        options: profileOptions,
      };
      const response = await APICALL.service(
        manageUser + "/" + user.userId,
        "POST",
        postdata
      );

      if (response?.status === 200) {
        updateState(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateState = (response: any) => {
    let data = response?.data;
    console.log("lang",data?.languages?.[0]?.id)
    setState((prevState: any) => ({
      ...prevState,
      form: {
        ...prevState.form,
        first_name: data?.profile?.first_name ?? "",
        last_name: data?.profile?.last_name ?? "",
        phone: data?.phone,
        role: data?.role ?? "",
        // roles: data?.roles,
        email: data?.profile?.email,
        language: data?.languages?.[0]?.id,
        user_id: user.userId,
        profile_picture: {
          // file: null,
          file_path: data?.profile?.profile_picture?.document_url,
          // file_name: data?.profile?.profile_picture?.file_name,
          fileId: data?.profile?.profile_picture?.id,
        },
      },
      options: response?.options,
    }));

  };


  const removeError = (fieldName: any) => {
    if (initialProfileState.errors.hasOwnProperty(fieldName)) {
      setState((prevState: any) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [fieldName]: "",
        },
      }));
    }
  };

  const handleProfileUpload = (file: File, field: any) => {
    const newFile: Files = {
      file,
      file_path: file && URL.createObjectURL(file),
      file_name: file?.name,
    };

    setState((prevState: any) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [field.name]: newFile,
      },
    }));
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    if (e != null) {
      const { name, value } = e.target ?? e;
      setState((prevState: any) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [field ?? name]: value,
        },
      }));
    } else {
      field &&
        setState((prevFormData: any) => ({ ...prevFormData, [field]: null }));
    }
  };
  const getRequestData = (fileId = null) => {
    const { first_name, last_name, language } = state.form;
    return {
      // profile_picture: fileId,
      first_name,
      last_name,
      language,
      user_id: user?.userId,
    };
  };

  const handleSubmit = async (errors: any, valid: boolean) => {
    if (valid) {
      try {
        let fileId = null;
        // const uploadPromises: Promise<void>[] = [];
        // let profile_picture = state?.form?.profile_picture;
        // const loadingIcon = document.getElementById("loading-div-id");
        // if (loadingIcon) loadingIcon.style.display = "block";
        // if ("file" in profile_picture && profile_picture.file) {
        //   const formData = new FormData();
        //   formData.append("file", profile_picture.file);
        //   formData.append("path", "profile");

        //   const response = await APICALL.service(fileData, "POST", formData);
        //   if (response?.status === 200) {
        //     fileId = response?.data?.fileId;
        //   } else {
        //     throw new Error("File upload failed");
        //   }
        // }
        // await Promise.all(uploadPromises);
        // if (loadingIcon) loadingIcon.style.display = "none";

        let postData = getRequestData(fileId);
        const response = await APICALL.service(createUser, "POST", postData);

        if (response?.status === 200) {
          CustomNotify({
            type: "success",
            message: t("Profile updated successfully"),
          });
          navigate("/");
        }
        if (response?.status === 400) {
          CustomNotify({
            type: "error",
            message: t("Error while updating the Profile"),
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setState((prevState: any) => ({
        ...prevState,
        errors: errors,
      }));
    }
  };

  return (
    <MyProfileForm
      removeError={removeError}
      handleChange={handleChange}
      options={state.options}
      data={state?.form}
      errors={state.errors}
      handleSubmit={handleSubmit}
      handleFileUpload={handleProfileUpload}
    />
  );
};

export default MyProfileOrganism;
