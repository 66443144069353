import './chats.css';
import React, { useState, useEffect, useRef } from 'react';
import io from "socket.io-client";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../features/auth/AuthSlice';
import { t } from '../../../services/translation/TranslationUtils';
import Send from '../../../utils/icons/Send';
import Seen from '../../../utils/icons/Seen';
import Unseen from '../../../utils/icons/Unseen';
import { CHATS_URL } from '../../../routes/ApiEndpoints';
import { BsArrowDownCircleFill } from "react-icons/bs";
import {chatsdata,offlinedata,messagedata} from "../../../pages/chats/state";

const socket = io(CHATS_URL);
socket.connect();

const ChatsOrganism: React.FC = () => {
    const auth = useSelector(selectAuth);
    const [message, setMessage] = useState("");
    const [user, setUser] = useState({
        fullname: "",
        username: ""
    });
    const [messages, setMessages] = useState<Record<string, []>>({});
    const [connectedChats, setConnectedChats] = useState<chatsdata[]>([]);
    const [selectedChat, setSelectedChat] = useState<chatsdata>({
        fullname: "",
        username: "",
        last_msg: {
            delivered: "",
            from: "",
            message: "",
            reciverfullname: "",
            seen: [],
            senderfullname: { fullname: "" },
            time: "",
            timestamp: "",
            to: "",
            _id: ""
        }
    });

    const [unreadMessages, setUnreadMessages] = useState<Record<string, number>>({});
    const [lastMessages, setLastMessages] = useState<Record<string, {
        message: string,
        time: string;
        from: string;
        seen: string | string[];
    }>>({});

    const [showScrollButton, setShowScrollButton] = useState(false);
    const messagesContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (auth?.username) {
            setUser({ fullname: auth?.fullname, username: auth?.username });
            socket.emit("joinuser", auth?.username);
            socket.on("update_chats", (chats: chatsdata[]) => {
                chats.map((chat) => {
                    if (chat.last_msg) {
                        setLastMessages((prevState) => ({
                            ...prevState,
                            [chat.username]: {
                                message: chat.last_msg.message,
                                time: chat.last_msg.time,
                                from: chat.last_msg.senderfullname.fullname,
                                seen: chat.last_msg.seen
                            }
                        }))
                    }
                })
                setConnectedChats(chats);
            });
            socket.once("receive_offline_message", (data: offlinedata[]) => {
                if (data.length === 0) return;
                data.forEach(userData => {
                    setUnreadMessages(prev => {
                        const updatedUnread = { ...prev, [userData.username]: userData.messages.length };
                        return updatedUnread;
                    });
                });
            });
        }
        return () => {
            socket.off("receive_offline_message");
            socket.off("update_chats");
        };
    }, [auth?.username]);

    const handleClick = (chat: chatsdata) => {
        setSelectedChat(chat);
        socket.emit("fetch_message", chat?.username);
        socket.on("allMessages", (data: messagedata[]) => {
            setMessages((prev) => ({
                ...prev,
                [chat.username]: groupMessagesByDate(data)
            }));
            console.log("msgdata", groupMessagesByDate(data));
            setUnreadMessages(prev => ({
                ...prev,
                [chat.username]: 0
            }));
            const lastMsg = data[data.length - 1];
            if (lastMsg) {
                let msgfrom = "";
                if (lastMsg.reciverfullname == '') {
                    msgfrom = lastMsg.to;
                } else {
                    if (auth?.username == lastMsg.from) {
                        msgfrom = lastMsg.to;
                    } else {
                        msgfrom = lastMsg.from;
                    }
                }
                if (msgfrom != '') {
                    setLastMessages((prevState) => ({
                        ...prevState,
                        [msgfrom]: {
                            message: lastMsg.message,
                            time: lastMsg.time,
                            from: lastMsg.senderfullname.fullname,
                            seen: lastMsg.seen
                        }
                    }))
                }
            }

        });
        socket.emit("set_seen", chat.username);
    };

    const handleFetch = (chat: chatsdata) => {
        setSelectedChat(chat);
        socket.emit("fetch_message", chat?.username);
        socket.on("allMessages", (data: messagedata[]) => {
            setMessages((prev) => ({
                ...prev,
                [chat.username]: groupMessagesByDate(data)
            }));
            const initialMsg = data[0];
            if (initialMsg.reciverfullname == '') {
                const unreadGroupCount = data.filter(
                    (msg) =>
                        msg.to === chat.username &&
                        !msg.seen.includes(auth.username)
                ).length;
                setUnreadMessages(prev => ({
                    ...prev,
                    [chat.username]: unreadGroupCount
                }));
            } else {
                const unreadUserCount = data.filter(
                    (msg) =>
                        msg.from === chat.username &&
                        !msg.seen === true
                ).length;
                setUnreadMessages(prev => ({
                    ...prev,
                    [chat.username]: unreadUserCount
                }));
            }
        });
    }

    const sendMessage = () => {
        if (message.trim() === "" || !selectedChat?.username) return;
        socket.emit("send_message", { to: selectedChat?.username, message });
        handleClick(selectedChat);
        setConnectedChats((prevChats: any[]) => {
            // Find existing user details if they are already in the list
            const existingUser = prevChats.find(user => user.username === selectedChat?.username);
            // If found, keep the fullname, otherwise use a default
            return [
                {
                    username: selectedChat?.username,
                    fullname: selectedChat?.fullname // Fallback to username if fullname is missing
                },
                ...prevChats.filter(user => user.username !== selectedChat?.username)
            ];
        });
        setMessage("")
    };

    useEffect(() => {
        socket.on("receive_message", (data: messagedata) => {
            if (auth?.username === data.to) {
                if (data.from === selectedChat?.username) {
                    if (messagesContainerRef.current && messagesContainerRef.current.scrollTop === 0) {
                        handleClick(selectedChat);
                    } else {
                        handleFetch(selectedChat);
                    }
                }
                setUnreadMessages((prev: any) => ({
                    ...prev,
                    [data.from]: (prev[data.from] || 0) + 1
                }));
                setLastMessages(prevLastMessages => ({
                    ...prevLastMessages,
                    [data.from]: { message: data.message, time: data.time, from: data.senderfullname.fullname, seen: data.seen }
                }));
                setConnectedChats((prevChats: any) => {
                    // Find existing user details if they are already in the list
                    const existingUser = prevChats.find((user: any) => user.username === data.from);
                    // If found, keep the fullname, otherwise use a default
                    return [
                        {
                            username: data.from,
                            fullname: existingUser ? existingUser.fullname : data.from // Fallback to username if fullname is missing
                        },
                        ...prevChats.filter((user: any) => user.username !== data.from)
                    ];
                });
            } else {
                if (data.to === selectedChat?.username) {
                    if (messagesContainerRef.current && messagesContainerRef.current.scrollTop == 0) {
                        handleClick(selectedChat);
                    } else {
                        handleFetch(selectedChat);
                    }
                    return;
                }
                setUnreadMessages((prev: any) => ({
                    ...prev,
                    [data.to]: (prev[data.to] || 0) + 1
                }));
                setLastMessages(prevLastMessages => ({
                    ...prevLastMessages,
                    [data.to]: { message: data.message, time: data.time, from: data.senderfullname.fullname, seen: data.seen }
                }));
                setConnectedChats((prevChats: any) => {
                    const existingGroup = prevChats.find((group: any) => group.username === data.to);
                    return [
                        {
                            username: data.to,
                            fullname: existingGroup ? existingGroup.fullname : data.to
                        },
                        ...prevChats.filter((group: any) => group.username !== data.to)
                    ];
                });
            }
            if (inputRef.current) {
                inputRef.current.focus();
            }
        });

        return () => {
            socket.off("receive_message");
        };
    }, [selectedChat?.username]);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 150);
    useEffect(() => {
        const updateWindowHeight = () => {
            setWindowHeight(window.innerHeight - 150);
        };
        window.addEventListener('resize', updateWindowHeight);
        return () => window.removeEventListener('resize', updateWindowHeight);
    }, []);

    const getFormattedDate = (time: string) => {
        const currentdate = new Date();
        const currenttimezoneOffset = currentdate.getTimezoneOffset() * 60 * 1000;
        const currentdateformattedDate = new Date(currentdate.getTime() - currenttimezoneOffset).toISOString().slice(0, 10);
        const date = new Date(time);
        const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
        const formattedDate = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, 10);
        if (currentdateformattedDate === formattedDate) {
            return "Today";
        } else if (date.getDate() === currentdate.getDate() - 1) {
            return "Yesterday";
        } else {
            return formattedDate;
        }
    };
    const groupMessagesByDate = (messages: messagedata[]) => {
        return messages.reduce((acc: any, msg: any) => {
            const formattedDate = getFormattedDate(msg.timestamp);
            if (!acc[formattedDate]) {
                acc[formattedDate] = [];
            }
            acc[formattedDate].push(msg);
            return acc;
        }, {});
    };
    const handleScroll = () => {
        if (messagesContainerRef.current && messagesContainerRef.current.scrollTop == 0) {
            setShowScrollButton(false);
        } else {
            setShowScrollButton(true);
        }
    };

    const scrollToBottom = () => {
        const container = messagesContainerRef.current;
        if (!container) return;
        setShowScrollButton(false);
        container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth"
        });
        setUnreadMessages(prev => ({
            ...prev,
            [selectedChat.username]: 0
        }));
        socket.emit("set_seen", selectedChat.username);
    };

    return (
        <div className="main chats" style={{ height: `${windowHeight}px` }}>
            <div className='user_block h-100 overflow-auto '>
                <div className='bg-white position-sticky top-0'>
                    <div className='username_block p-3'>
                        <div className='d-flex justify-content-between'>
                            <div className='username'>
                                {user?.fullname && user?.fullname}
                            </div>
                        </div>
                    </div>
                    <div className='px-3 p-2 border-top border-bottom border-3'>{t("Chats")}</div>
                </div>
                <div className='mt-2 bg-white'>
                    {connectedChats.filter((chatObj) => chatObj.username !== user?.username).map((chatObj) => (
                        <div className='users_names p-2 px-3 d-flex justify-content-between border-bottom' key={chatObj.username} onClick={() => handleClick(chatObj)} style={{ cursor: 'pointer', background: selectedChat?.username === chatObj.username ? '#00a5ce4d' : '' }}>
                            <div className='w-100'>
                                <div className='d-flex justify-content-between'>
                                    <span className='text-truncate'>{chatObj.fullname}</span>
                                    <small>{lastMessages[chatObj.username]?.time}</small>
                                </div>
                                <div className='last_message d-flex justify-content-between'>
                                    {/* <small className='text-truncate'>{lastMessages[chatObj.username]?.from} {" "} {lastMessages[chatObj.username]?.message}</small> */}
                                    <small className='text-truncate'>
                                        {lastMessages[chatObj.username]?.from === user?.fullname
                                            ? "You"
                                            : lastMessages[chatObj.username]?.from}
                                        {" "}
                                        {lastMessages[chatObj.username]?.message}
                                    </small>
                                    {unreadMessages[chatObj.username] > 0 && <span className='msg_count'>{unreadMessages[chatObj.username]}</span>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='message_block h-100 overflow-auto'>
                <div className='container h-100'>
                    <div className='d-flex flex-column h-100'>
                        <div className='connected_user border-bottom p-3'>
                            {t("Connected to")}:  {selectedChat?.fullname}
                            <div>
                            </div>
                        </div>
                        <div className="message-history flex-grow-1 overflow-auto pe-3" ref={messagesContainerRef} onScroll={handleScroll}>
                            {messages[selectedChat?.username] != undefined && Object.entries(messages[selectedChat?.username] || {}).reverse().map(([date, msgs]: [any, any]) => (
                                <div key={date}>
                                    <div className='msgdate'><span className='mx-3'>{date}</span></div>
                                    {msgs.map((msg: any, index: any) => (
                                        <div key={index} className={`${msg.from === user?.username ? 'text-end' : 'text-start'} my-3`}>
                                            {index === messages[selectedChat?.username].length - unreadMessages[selectedChat?.username] && (
                                                <div className='msgdate'><span className='mx-3'>unread messages</span></div>
                                            )}
                                            <div className='msg_time mb-1'>{msg.senderfullname.fullname === user?.fullname ? "" : msg.senderfullname.fullname} {msg.time}</div>
                                            <span className={`message ${msg.from === user?.username ? 'own' : 'other'} p-2 rounded text-break`}>
                                                {msg.message} {msg.from === user?.username && (msg.seen ? <Seen /> : <Unseen />)}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                            {showScrollButton && (
                                <div className="relative">
                                    <button className="scroll-down-btn" onClick={scrollToBottom}>
                                        <BsArrowDownCircleFill size={40} />
                                    </button>
                                    {/* {unreadMessages[selectedChat?.username] > 0 && <span className="unread-badge">{unreadMessages[selectedChat?.username]}</span>} */}
                                </div>
                            )}
                            {unreadMessages[selectedChat?.username] > 0 && messagesContainerRef.current?.scrollTop !== 0 && (
                                <span className="unread-badge">{unreadMessages[selectedChat?.username]}</span>
                            )}
                        </div>
                        <div className='d-flex my-3' >
                            <input ref={inputRef} className='form-control me-2' placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    sendMessage();
                                }
                            }} />
                            <div onClick={sendMessage} className='msgSend_btn'><Send /></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatsOrganism;
