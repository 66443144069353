import { FormMode } from "../../../components/common/CommonEnums";
import { options } from "../../../components/organisms/users/State";
import { FormState } from "./Interfaces";

export const initialState: FormState = {
  mode: FormMode.CREATE,
  tabs: [
    {
      id: "general_details",
      title: "General Details",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "layout",
      title: "Layout",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "administrators",
      title: "Administrators",
      showStatus: true,
      error: false,
      draft: false,
    },
  ],
  general_details: {
    data: {
      title: "",
      key: "",
      form_type: null,
      isActive: false,
      private_form: false,
      record_on_user_profile: false,
      disable_result_export: false,
      anonymous: false,
      show_in_library: false,
      public: false,
      single_completion: false,
      has_expiration_date: false,
      show_results_to_user: false,
    },
    errors: {
      title: "",
      form_type: "",
    },
  },
  layout: {
    data: {
      fields: [],
      currentField: null,
      editMode: {
        isEditing: false,
        fieldId: null,
      },
    },
    errors: {},
  },
  administrators: {
    data: {
      manager: [],
      user: [],
      team: [],
      admin: [],
    },
    errors: {
      manager: "",
      user: "",
      team: "",
      admin: "",
    },
  },
  currentTab: "general_details",
  options: {
    manager_options: [],
    user_options: [],
    team_options: [],
    admin_options: [],
    formType_options: [],
    form_element_options: [],
    date_format_options: [],
    time_format_options: [],
    field_action_options: [],
  },
  initialCall: true,
};

export const OptionsPayload = {
  data: [
    {
      model: "manager_options",
      filters: { status: [true] },
    },
    {
      model: "user_options",
      filters: { status: [true] },
    },
    {
      model: "team_options",
      filters: { status: [true] },
    },
    {
      model: "admin_options",
      filters: { status: [true] },
    },
  ],
};

export const getFormFields = (state: FormState) => {
  const formFields: any = {
    general_details: [
      {
        0: {
          name: "title",
          title: "Title",
          type: "text",
          required: true,
          class: {},
        },
      },
      {
        0: {
          name: "form_type",
          placeholder: "Form Type",
          type: "select",
          required: true,
          value: null,
          options: state["options"]?.formType_options ?? [],
          class: {},
        },
      },
      {
        0: {
          name: "isActive",
          title: "Active",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
          },
        },
      },
      {
        0: {
          name: "private_form",
          title: "Private Form",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        1: {
          name: "anonymous",
          title: "Anonymous",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        2: {
          name: "single_completion",
          title: "Single Completion",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
      },
      {
        0: {
          name: "record_on_user_profile",
          title: "Record on user profile",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        1: {
          name: "show_in_library",
          title: "Show in library",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        2: {
          name: "has_expiration_date",
          title: "Has Expiration Date",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
      },
      {
        0: {
          name: "disable_result_export",
          title: "Disable result export",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        1: {
          name: "public",
          title: "Public",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        2: {
          name: "show_results_to_user",
          title: "Show results to user",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
      },
    ],
    administrators: [
      {
        0: {
          name: "manager",
          title: "Process Manager",
          type: "multi-select",
          // required: true,
          value: [],
          options: state["options"]?.manager_options ?? [],
          class: {},
        },
      },
      {
        0: {
          name: "user",
          title: "Employees",
          type: "multi-select",
          // required: true,
          value: [],
          options: state["options"]?.user_options ?? [],
          class: {},
        },
      },
      {
        0: {
          name: "team",
          title: "Team/Department",
          type: "multi-select",
          value: [],
          options: state.options?.team_options ?? [],
          class: {},
        },
      },
      {
        0: {
          name: "admin",
          title: "Admin",
          type: "multi-select",
          // required: true,
          value: [],
          options: state.options?.admin_options ?? [],
          class: {},
        },
      },
    ],
  };
  return formFields[state.currentTab] || [];
};

export const tableHeaders = [
  { name: "Title", width: 25 },
  { name: "Form type", width: 25 },
  { name: "created_at", width: 25, isSort: true },
  { name: "Activity", width: 10 },
];

export const tableValues = ["title", "form_type", "created_at", "activity"];

export const manageState = {
  data: [],
  options: [],
  filters:{},
  postData: {
    limit: 10,
    current_page: 1,
    search: "",
    total_pages: 1,
    status: null,
    order_type: "created_at",
    form_type:"",
    order: "DESC",
  },
  popup: false,
};

export const viewState = {
  data: [] as any,
  isFetching: false,
  postData: {
    limit: 10,
    current_page: 1,
    last: false,
    options: true,
  },
  options: [],
};

export const manageFormState = {
  filters: {
    user: "",
    title: "",
    type:"",
  },
  options: {
    title: [],
    type: [],
  } as any,
  data: [],
  postData: {
    limit: 10,
    current_page: 1,
    user: "",
    title: "",
    type:"",
    total_pages: 1,
    order: "DESC",
  },
  popup: false,
};

export const formViewHeaders = [
  { name: "User Name", width: 20 },
  { name: "Title", width: 20 },
  { name: "Form Type", width: 25 },
  { name: "Created At", width: 20, isSort: true },
];

export const formViewValues = ["username", "title","form_type","created_at"];

