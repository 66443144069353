import { PdfProps } from "../../../../pages/news/context/Interfaces";
import '../CreateNewsOrganism.css';

const ShowPdf: React.FC<PdfProps> = ({
    pdf
}) => {
    return (
        <div className="mb-2">
            <ul>
                {pdf?.map((item: any) => (
                    (item?.file_path) && 
                    (<li className="cursor-pointer" key={item?.file_name}>
                        <a href={item?.url} target="_blank" rel="noopener noreferrer">
                            {item?.file_name ?? item?.file_path}
                        </a>
                    </li>)
                ))}
            </ul>
        </div>
    );
}

export default ShowPdf;