import { ChangeEvent, useState } from "react";
import { CommentsProps } from "../../../../pages/news/context/Interfaces";
import { ACTION_POSTS } from "../../../../routes/ApiEndpoints";
import { APICALL } from "../../../../services/ApiServices";
import Button from "../../../atoms/Button";
import InputTextAreaField from "../../../atoms/InputTextAreaField";
import LabelField from "../../../atoms/LabelField";
import UserNameAndProfile from "./UserNameAndProfile";
import Like from "../../../../utils/icons/Like";

const Comments: React.FC<CommentsProps> = ({
    postId,
    comments,
    fetchPost
}) => {
    const [state, setState] = useState({
        comment: ''
    });
    const handleSubmit = async () => {
        let postData = {
            type: 'comment',
            entity_type: 'post',
            entity_id: postId,
            comment: state.comment
        };

        const response = await APICALL.service(ACTION_POSTS, 'POST', postData);

        if (response.status === 200) {
            setState((prevState: any) => ({
                ...prevState,
                comment: ''
            }));
            fetchPost(postId);
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setState((prevState: any) => ({
            ...prevState,
            comment: e.target.value
        }));
    }

    const handleLike = async (comment: any) => {
        let postData = {
            type: 'like',
            entity_type: 'comment',
            entity_id: comment?.id,
            status: !comment?.has_liked
        };
        const response = await APICALL.service(ACTION_POSTS, 'POST', postData);

        if (response.status === 200) {
            fetchPost(postId);
        }
    };

    return (
        <div className="my-2">
            {comments?.map((comment: any) => (
                <div key={comment?.id} className="my-3">
                    <UserNameAndProfile
                        user={comment?.comment_user}
                        created_at={comment?.created_at}
                    />
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col">
                            <LabelField
                                title={comment?.comment}
                            />
                            <div>
                                <span
                                    onClick={() => handleLike(comment)}
                                >
                                    <Like liked={comment?.has_liked} />
                                </span> {comment?.likes_count} {' '}
                            </div>
                        </div>
                    </div>

                </div>
            ))}
            <div>
                <InputTextAreaField
                    value={state.comment}
                    handleChange={handleChange}
                />
                <div className="d-flex justify-content-end mt-3">
                    <Button
                        className="search_btn"
                        title={'Submit'}
                        handleClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default Comments;