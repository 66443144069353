import { createBrowserRouter } from "react-router-dom";
import LoginForm from "../pages/authentication/LoginForm";
import ManageGroup from "../pages/groups/ManageGroup";
import {
  CREATE_USER_PATH,
  PATH_CREATE_GROUP,
  MANAGE_USER_PATH,
  PATH_CREATE_NEWS,
  PATH_CREATE_POST,
  PATH_MANAGE_GROUP,
  PATH_MANAGE_NEWS,
  LOGIN_PATH,
  FORGOT_PASSWORD_PATH,
  PATH_MANAGE_TRANSLATION,
  PATH_CREATE_DOCUMENTS,
  PATH_MANAGE_DOCUMENTS,
  PATH_VIEW_NEWS,
  PATH_CREATE_ALERTS,
  PATH_CHATS,
  PATH_MANAGE_ALERTS,
  MY_PROFILE_PATH,
  RESET_PASSWORD_PATH,
  PASSWORD_PATH,
  PATH_CREATE_FORMS,
  PATH_MANAGE_FORMS,
  PATH_VIEW_FORMS,
  PATH_MANAGE_FAQ,
  EDIT_FAQ,
  PATH_VIEW_FORM_DATA,
 
} from "../constants/Paths";
import CreateGroup from "../pages/groups/index";
import CreateUser from "../pages/users/CreateUser";
import Layout from "../components/layouts/RootLayout";
import PublicRoute from "./PublicRoute";
import ManageUser from "../pages/users/ManageUser";
import CreateNews from "../pages/news";
import ManageNews from "../pages/news/ManageNews";
import CreatePost from "../pages/posts/CreatePost";
import Dashboard from "../pages/dashboard";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import ManageTranslations from "../pages/translations/ManageTranslations";
import CreateDocuments from "../pages/documents";
import ManageDocuments from "../pages/documents/ManageDocuments";
import ViewNews from "../pages/news/ViewNews";
import CreateAlert from "../pages/alerts/CreateAlert";
import Chats from "../pages/chats/Chats";
import ManageAlert from "../pages/alerts/ManageAlert";
import MyProfile from "../pages/users/MyProfile";
import ResetPassword from "../pages/authentication/ResetPassword";
// import ChangePassword from "../pages/users/ChangePassword";
import PageNotFound from "../pages/PageNotFound";
import ManageForm from "../pages/forms/ManageForm";
import ViewForm from "../pages/forms/ViewForm";
import CreateForm from "../pages/forms/CreateForm";
import ManageFaq from "../pages/Faq/ManageFaq";
import Faq from "../pages/Faq/Faq";
import FormBuilder from "../components/templates/forms/FormBuilder";
import ManageFormData from "../pages/forms/ManageFormData";

export const Routes = createBrowserRouter([
  { path: `${LOGIN_PATH}`, element: <PublicRoute element={<LoginForm />} /> },
  {
    path: `${FORGOT_PASSWORD_PATH}`,
    element: <PublicRoute element={<ForgotPassword />} />,
  },
  {
    path: `${RESET_PASSWORD_PATH}`,
    element: <PublicRoute element={<ResetPassword />} />,
  },
  {
    path: `/`,
    element: <Layout />,
    children: [
      { path: `/`, element: <Dashboard /> },
      // { path: `${CREATE_USER_PATH}`, element: <CreateUser /> },
      // { path: `${PASSWORD_PATH}`, element: <ChangePassword /> },
      { path: `${PATH_CREATE_GROUP}`, element: <CreateGroup /> },
      { path: `${PATH_MANAGE_GROUP}`, element: <ManageGroup /> },
      // { path: `${MANAGE_USER_PATH}`, element: <ManageUser /> },
      { path: `${MY_PROFILE_PATH}`, element: <MyProfile /> },
      { path: `${PATH_CREATE_NEWS}`, element: <CreateNews /> },
      { path: `${PATH_MANAGE_NEWS}`, element: <ManageNews /> },
      { path: `${PATH_CREATE_POST}`, element: <CreatePost /> },
      { path: `${PATH_MANAGE_TRANSLATION}`, element: <ManageTranslations /> },
      { path: `${PATH_CREATE_DOCUMENTS}`, element: <CreateDocuments /> },
      { path: `${PATH_MANAGE_DOCUMENTS}`, element: <ManageDocuments /> },
      { path: `${PATH_VIEW_NEWS}`, element: <ViewNews /> },
      { path: `${PATH_CREATE_ALERTS}`, element: <CreateAlert /> },
      { path: `${PATH_MANAGE_ALERTS}`, element: <ManageAlert /> },
      { path: `${PATH_CHATS}`, element: <Chats /> },
      // { path: `${PATH_CREATE_FORMS}`, element: <FormBuilder /> },
      { path: `${PATH_CREATE_FORMS}`, element: <CreateForm /> },
      { path: `${PATH_MANAGE_FORMS}`, element: <ManageForm /> },
      { path: `${PATH_VIEW_FORM_DATA}`, element: <ManageFormData /> },
      { path: `${PATH_MANAGE_FAQ}`, element: <ManageFaq /> },
      { path: `${EDIT_FAQ}`, element: <Faq /> },
    ],
  },
  { path: "*", element: <PageNotFound /> },
  { path: `${PATH_VIEW_FORMS}`, element: <ViewForm /> },
]);
