import Button from "../../components/atoms/Button";
import ErrorMessage from "../../components/atoms/ErrorMessage";
import InputTextAreaField from "../../components/atoms/InputTextAreaField";
import InputTextfield from "../../components/atoms/InputTextField";
import LabelField from "../../components/atoms/LabelField";
import MultiSelect from "../../components/atoms/MultiSelectField";
import RadioField from "../../components/atoms/RadioField";
import EditorComponent from "../../components/molecules/CKEditor";
import CheckBoxField from "../../components/atoms/CheckBoxField";
import PhoneInput from "react-phone-number-input";
import CommonServices from "../CommonService";
import ReactDatePicker from "react-datepicker";
import FileUploadComponent from "../../components/molecules/FileUploadMolecule";
import ToggleSwitch from "../../components/atoms/ToggleSwitch";
import Calendar from "../../utils/icons/Calendar";
import { t } from "../translation/TranslationUtils";
import PaginatedListSelectField from "../../components/molecules/PaginatedListSelectField";
import SingleCheckBoxField from "../../components/atoms/SingleCheckbox";
import ViewPreviewFile from "../../components/molecules/ViewPreviewFile";

interface Option {
  value: number;
  label: string;
}

export const renderInput = (
  removeError: any,
  field: any,
  handleInputChange: any,
  formData?: any,
  formErrors?: any,
  search?: any,
  handleDateFormat?:any,
) => {
  switch (field?.type) {
    case "text":
      return (
        <div className={field?.class?.mainclass ?? ""}>
          {!field?.label && (
            <div className={field?.class?.labelclass ?? ""}>
              <LabelField
                title={CommonServices.capitalizeLabel(
                  t(field?.title ?? field?.name)
                )}
                mandatory={field?.required}
              />
            </div>
          )}
          <div className={field?.class?.feildclass ?? ""}>
            <InputTextfield
              name={field?.name}
              className={`form-control shadow-none h-100 mh-38 ${
                formErrors?.[field?.name] ? "border-danger" : ""
              }`}
              placeholder={t(field?.placeholder)}
              value={formData?.[field?.name]}
              handleChange={(e) => handleInputChange(e, field?.name)}
              handleClick={() => removeError(field?.name)}
              readOnly={field?.disable}
            />
            {formErrors?.hasOwnProperty(field?.name) && (
              <ErrorMessage errorMessage={formErrors?.[field.name]} />
            )}
          </div>
        </div>
      );

      case "email":
        return (
          <div className={field?.class?.mainclass ?? ""}>
            {!field?.label && (
              <div className={field?.class?.labelclass ?? ""}>
                <LabelField
                  title={CommonServices.capitalizeLabel(
                    t(field?.title ?? field?.name)
                  )}
                  mandatory={field?.required}
                />
              </div>
            )}
            <div className={field?.class?.feildclass ?? ""}>
              <InputTextfield
                name={field?.name}
                type="email"
                className={`form-control shadow-none h-100 mh-38 ${
                  formErrors?.[field?.name] ? "border-danger" : ""
                }`}
                placeholder={t(field?.placeholder)}
                value={formData?.[field?.name]}
                handleChange={(e) => handleInputChange(e, field?.name)}
                handleClick={() => removeError(field?.name)}
                readOnly={field?.disable}
              />
              {formErrors?.hasOwnProperty(field?.name) && (
                <ErrorMessage errorMessage={formErrors?.[field.name]} />
              )}
            </div>
          </div>
        );

    case "textarea":
      return (
        <div className={field?.class?.mainclass ?? ""}>
          <LabelField
            title={CommonServices.capitalizeLabel(
              t(field?.title ?? field?.name)
            )}
            mandatory={field.required}
          />
          <div className={field?.class?.feildclass ?? ""}>
            <InputTextAreaField
              name={field?.name}
              className={`form-control shadow-none ${
                formErrors?.[field?.name] ? "border-danger" : ""
              }`}
              handleChange={(e) => handleInputChange(e, field?.name)}
              value={formData[field?.name]}
              handleClick={() => removeError(field?.name)}
              isDisabled={field?.disable}
            />
          </div>
          {formErrors.hasOwnProperty(field.name) && (
            <ErrorMessage errorMessage={formErrors[field.name]} />
          )}
        </div>
      );

    case "buttonwithpopup":
      return (
        <div className={field.class?.mainclass ?? ""}>
          <div
            className={field.class?.labelclass ?? "d-flex align-items-center"}
          >
            <LabelField
              title={CommonServices.capitalizeLabel(
                t(field?.title ?? field?.name)
              )}
              mandatory={field.required}
            />
          </div>
          <div className={field?.class?.buttonclass ?? ""}>
            <Button
              name={field.name}
              title={
                field.options.find(
                  (option: any) => option?.value == field?.value
                )?.label
              }
              className={`text-truncate w-100 back-btn d-block ${
                field?.error ? "border-danger" : ""
              }`}
              disabled={field?.disable}
              handleClick={(e) => handleInputChange(e, field?.name)}
            />
            {formErrors.hasOwnProperty(field.name) && (
              <ErrorMessage errorMessage={formErrors[field.name]} />
            )}
          </div>
        </div>
      );

    case "select":
      return (
        <div className={field.class?.mainclass ?? ""}>
          <div
            className={field.class?.labelclass ?? "d-flex align-items-center"}
          >
            <LabelField
              title={CommonServices.capitalizeLabel(
                t(field?.title ?? field?.name)
              )}
              mandatory={field.required}
            />
          </div>
          <div className={field?.class?.feildclass ?? ""}>
            <MultiSelect
              name={field?.name}
              className={`shadow-none`}
              classNamePrefix={"react-select-prefix"}
              placeholder={t(field?.placeholder ?? "Select")}
              options={field?.options}
              standards={field?.options?.find(
                (option: Option) => option?.value == formData[field.name]
              )}
              handleChange={(e) => handleInputChange(e, field?.name)}
              handleClick={() => removeError(field?.name)}
              customStyle={
                formErrors[field.name]
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : undefined
              }
              isClearable={true}
              isSearchable={true}
              disabled={field?.disable}
            />
            {formErrors.hasOwnProperty(field.name) && (
              <ErrorMessage errorMessage={formErrors[field.name]} />
            )}
          </div>
        </div>
      );

    case "multi-select":
      return (
        <div className={field.class?.mainclass ?? ""}>
          <div
            className={field.class?.labelclass ?? "d-flex align-items-center"}
          >
            <LabelField
              title={CommonServices.capitalizeLabel(
                t(field?.title ?? field?.name)
              )}
              mandatory={field.required}
            />
          </div>
          <div className={field?.class?.feildclass ?? ""}>
            <MultiSelect
              name={field?.name}
              className={"shadow-none"}
              classNamePrefix={"react-select-prefix"}
              placeholder={t(field?.placeholder ?? "Select")}
              options={field.options}
              standards={field?.options?.filter((option: Option) =>
                formData[field?.name]?.includes(option?.value)
              )}
              handleChange={(selectedValues) =>
                handleInputChange(
                  (selectedValues as any[]).map((option) => option?.value),
                  field?.name
                )
              }
              customStyle={
                formErrors[field?.name]
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : undefined
              }
              handleClick={() => removeError(field?.name)}
              isClearable={true}
              isSearchable={true}
              isMulti={true}
              disabled={field?.disable}
            />
            {formErrors.hasOwnProperty(field.name) && (
              <ErrorMessage errorMessage={formErrors[field?.name]} />
            )}
          </div>
        </div>
      );

    case "editor":
      return (
        <EditorComponent
          key={field?.name}
          data={formData[field?.name]}
          name={field?.name}
          error={formErrors[field?.name]}
          onChange={(e) => handleInputChange(e, field?.name)}
          onFocus={() => removeError(field?.name)}
          mandatory={field.required}
          maindivclass={field?.class?.mainclass ?? ""}
        />
      );

    case "radio":
      return (
        <div className={field.class?.mainclass ?? ""}>
          <LabelField
            title={CommonServices.capitalizeLabel(
              t(field?.title ?? field?.name)
            )}
            mandatory={field?.required}
          />
          <div className={field?.class?.feildclass ?? ""}>
            {field?.options?.map((statusValue: any) => (
              <RadioField
                className={"my-2 me-4 d-inline-block"}
                key={statusValue?.value}
                name={statusValue?.name}
                value={statusValue?.value}
                checked={formData[field?.name] == statusValue?.value}
                handleChange={(e) => handleInputChange(e, field?.name)}
                handleClick={() => removeError(field?.name)}
                label={statusValue.label}
                id={field?.name}
                disable={field?.disable}
              />
            ))}
          </div>
          <div className="height-20 mb-2">
            {formErrors[field?.name] && (
              <span className="text-danger">{formErrors[field?.name]}</span>
            )}
          </div>
        </div>
      );

      case "single_checkbox":
        return (
          <div className={field.class?.mainclass ?? ""}>
            <div className={field.class?.feildclass ?? ""}>
            <SingleCheckBoxField
            name= {field?.name}
            label= {field?.title}
            checked= {formData[field?.name] ?? false}
            clicked={(e) => handleInputChange(e, field?.name)}
            handleSelect= {() => removeError(field?.name)}
            mandatory= {field?.required}
            disable={field?.disable}
            />
            </div>
            <div className="height-20 mb-2">
              {formErrors[field?.name] && (
                <span className="text-danger">{formErrors[field?.name]}</span>
              )}
            </div>
          </div>
        );

    case "checkbox":
      return (
        <div className={field.class?.mainclass ?? ""}>
          <LabelField
            title={CommonServices.capitalizeLabel(
              t(field?.title ?? field?.name)
            )}
            mandatory={field?.required}
          />
          <div className={field.class?.feildclass ?? ""}>
            {field?.options.map((option: any) => (
              <CheckBoxField
                key={option?.value}
                name={option?.name}
                checked={formData[field?.name]?.includes(option?.value)}
                clicked={(e) => handleInputChange(e, field?.name)}
                label={option?.label}
                id={option?.value}
              />
            ))}
          </div>
          <div className="height-20 mb-2">
            {formErrors[field?.name] && (
              <span className="text-danger">{formErrors[field?.name]}</span>
            )}
          </div>
        </div>
      );
    case "listwithselect":
      return (
        <PaginatedListSelectField
          field={field}
          handleInputChange={handleInputChange}
          formData={formData}
          search={search}
        />
      );

    case "date":
      const minDate =
        field.name === "to" && formData.from ? formData.from : field?.mindate;
      const maxDate =
        field.name === "from" && formData.to ? formData.to : field?.maxdate;
      return (
        <div className={field.class?.mainclass ?? ""}>
          {!field?.label && (
            <div className={field.class?.labelclass ?? ""}>
              <LabelField
                title={CommonServices.capitalizeLabel(
                  t(field?.title ?? field?.name)
                )}
                mandatory={field?.required}
              />
            </div>
          )}
          <div className={field.class?.feildclass ?? "position-relative"}>
            <ReactDatePicker
              autoComplete="off"
              name={field?.name}
              dateFormat={"dd-MM-yyyy"}
              selected={
                formData[field?.name] ? new Date(formData[field?.name]) : null
              }
              showMonthDropdown
              showYearDropdown
              placeholderText={t(field?.placeholder)}
              className={`w-100 form-control ${
                formErrors?.[field?.name] ? "border-danger" : ""
              }`}
              calendarStartDay={1}
              dropdownMode="select"
              isClearable={!field?.disable}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(dateValue: any) =>
                handleInputChange(dateValue, field?.name)
              }
              onKeyDown={(e: any) => {
                if (
                  e.target.value.length >= 10 &&
                  e.key !== "Backspace" &&
                  e.key !== "Delete"
                ) {
                  e.preventDefault();
                }
              }}
              onFocus={() => removeError(field.name)}
              disabled={field?.disable}
            />
            {!formData[field?.name] && (
              <span className="date_picker_calendar_icon me-2">
                <Calendar />
              </span>
            )}
            {formErrors?.hasOwnProperty(field.name) && (
              <ErrorMessage errorMessage={formErrors[field.name]} />
            )}
          </div>
        </div>
      );

    case 'datetime':
      return(
        <div className={field.class?.mainclass ?? ""}>
          {!field?.label && (
            <div className={field.class?.labelclass ?? ""}>
              <LabelField
                title={CommonServices.capitalizeLabel(
                  t(field?.title ?? field?.name)
                )}
                mandatory={field?.required}
              />
            </div>
          )}
          <div className={field.class?.feildclass ?? "position-relative"}>
          <InputTextfield
                name= {field?.name}
                type= 'datetime-local'
                className= {`form-control shadow-none h-100 mh-38 ${
                  formErrors?.[field?.name] ? "border-danger" : ""
                }`}
                placeholder={t(field?.placeholder)}
                value={formData?.[field?.name]}
                handleChange={(e) => handleInputChange(e, field?.name)}
                handleClick={() => removeError(field?.name)}
                readOnly={field?.disable}
              />
              {formErrors?.hasOwnProperty(field?.name) && (
                <ErrorMessage errorMessage={formErrors?.[field.name]} />
              )}
          </div>
        </div>
      );

    case "multi-select-without-label":
      return (
        <div className={field.class?.feildclass ?? ""}>
          <MultiSelect
            name={field.name}
            className={"shadow-none"}
            classNamePrefix={"react-select-prefix"}
            placeholder={t(field?.placeholder)}
            options={field.options}
            standards={field.options?.filter((option: Option) =>
              formData?.[field?.name]?.includes(option.value)
            )}
            handleChange={(selectedValues) =>
              handleInputChange(
                (selectedValues as any[]).map((option) => option?.value),
                field?.name
              )
            }
            customStyle={
              formErrors?.[field?.name]
                ? { border: "1px solid red", borderRadius: "5px" }
                : undefined
            }
            handleClick={() => removeError(field.name)}
            isClearable={true}
            isSearchable={true}
            isMulti={true}
            disabled={field?.disable}
          />
        </div>
      );

    case "single-select-without-label":
      return (
        <div className={field.class?.feildclass ?? ""}>
          <MultiSelect
            name={field.name}
            className={"shadow-none"}
            classNamePrefix={"react-select-prefix"}
            placeholder={t(field?.placeholder)}
            options={field.options}
            standards={field?.options?.find(
              (option: Option) => option?.value == formData?.[field?.name]
            )}
            handleChange={(e) =>
              handleInputChange(e?.value ?? null, field?.name)
            }
            handleClick={() => removeError(field?.name)}
            customStyle={
              formErrors?.[field?.name]
                ? { border: "1px solid red", borderRadius: "5px" }
                : undefined
            }
            isClearable={false}
            isSearchable={true}
            disabled={field?.disable}
          />
        </div>
      );

    case "file":
      return (
        <FileUploadComponent
          field={field}
          removeError={removeError}
          handleChange={handleInputChange}
          value={formData[field?.name]}
          error={formErrors[field?.name]}
        />
      );

    case "switch":
      return (
        <div className={field.class?.feildclass ?? ""}>
          <ToggleSwitch
            id={field?.name}
            key={field?.name}
            name={field?.name}
            title={t(field?.title)}
            toggleclassName={field?.class?.toggleclass}
            checked={formData[field?.name]}
            onChange={(e) => handleInputChange(e, field?.name)}
          />
        </div>
      );

    case "svg":
      return (
        <div
          title={t(field?.hovertext)}
          className="cursor-pointer px-2"
          onClick={(e) => handleInputChange(e, field)}
        >
          {field?.svg}
        </div>
      );

    case "phone-number":
      return (
        <div className={field?.class?.mainclass ?? ""}>
          {!field?.label && (
            <div className={field?.class?.labelclass ?? ""}>
              <LabelField
                title={CommonServices.capitalizeLabel(
                  t(field?.title ?? field?.name)
                )}
                mandatory={field?.required}
              />
            </div>
          )}
          <div className={field?.class?.feildclass ?? ""}>
            <div className="form-control shadow-none">
              <PhoneInput
                className="d-flex"
                name={field?.name}
                required={field?.required}
                defaultCountry="BE"
                international
                initialValueFormat="national"
                placeholder={t("Phone number")}
                onChange={(e) => handleInputChange(e, field?.name)}
                value={formData?.[field?.name]}
                id={field.name}
                disabled={field?.disable}
              />
            </div>
            {formErrors?.hasOwnProperty(field?.name) && (
              <ErrorMessage errorMessage={formErrors?.[field.name]} />
            )}
          </div>
        </div>
      );
    case "time":
        return (
          <div className={field?.class?.mainclass ?? ""}>
            {!field?.label && (
              <div className={field?.class?.labelclass ?? ""}>
                <LabelField
                  title={CommonServices.capitalizeLabel(
                    t(field?.title ?? field?.name)
                  )}
                  mandatory={field?.required}
                />
              </div>
            )}
            <div className={field?.class?.feildclass ?? ""}>
              <div className="form-control shadow-none">
                <InputTextfield
                name= {field?.name}
                type= {field?.type}
                className= {`form-control shadow-none h-100 mh-38 ${
                  formErrors?.[field?.name] ? "border-danger" : ""
                }`}
                placeholder={t(field?.placeholder)}
                value={formData?.[field?.name]}
                handleChange={(e) => handleInputChange(e, field?.name)}
                handleClick={() => removeError(field?.name)}
                readOnly={field?.disable}
              />
              </div>
              {formErrors?.hasOwnProperty(field?.name) && (
                <ErrorMessage errorMessage={formErrors?.[field.name]} />
              )}
            </div>
          </div>
        );

        case "url":
          return (
            <div className={field?.class?.mainclass ?? ""}>
            {!field?.label && (
              <div className={field?.class?.labelclass ?? ""}>
                <LabelField
                  title={CommonServices.capitalizeLabel(
                    t(field?.title ?? field?.name)
                  )}
                  mandatory={field?.required}
                />
              </div>
            )}
            <div className={field?.class?.feildclass ?? ""}>
              <InputTextfield
                name= {field?.name}
                type= 'url'
                className= {`form-control shadow-none h-100 mh-38 ${
                  formErrors?.[field?.name] ? "border-danger" : ""
                }`}
                placeholder={t(field?.placeholder)}
                value={formData?.[field?.name]}
                handleChange={(e) => handleInputChange(e, field?.name)}
                handleClick={() => removeError(field?.name)}
                readOnly={field?.disable}
              />
              {formErrors?.hasOwnProperty(field?.name) && (
                <ErrorMessage errorMessage={formErrors?.[field.name]} />
              )}
            </div>
          </div>
          );

    case "hr":
      return (
        <hr/>
      );

    case "h1":
      return (
        <h1>{field?.title}</h1>
      );
    case "h2":
      return (
        <h2>{field?.title}</h2>
      );

    case "h3":
      return (
        <h3>{field?.title}</h3>
      );

    case "dropdown":
        return (
          <div className={field.class?.mainclass ?? ""}>
            <div
              className={field.class?.labelclass ?? "d-flex align-items-center"}
            >
              <LabelField
                title={CommonServices.capitalizeLabel(
                  t(field?.title ?? field?.name)
                )}
                mandatory={field.required}
              />
            </div>
            <div className={field?.class?.feildclass ?? ""}>
            <MultiSelect
              name={field?.name}
              className={`shadow-none`}
              classNamePrefix={"react-select-prefix"}
              placeholder={t(field?.placeholder ?? "Select")}
              options={field?.options}
              standards={field?.options?.find(
                (option: Option) => option?.value == formData[field.name]
              )}
              handleChange={(e) => handleInputChange(e, field?.name)}
              handleClick={() => removeError(field?.name)}
              customStyle={
                formErrors[field.name]
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : undefined
              }
              isClearable={true}
              isSearchable={true}
              disabled={field?.disable}
            />
            {formErrors.hasOwnProperty(field.name) && (
              <ErrorMessage errorMessage={formErrors[field.name]} />
            )}
          </div>
          </div>
        );

    case "location_time":
      return(
        <div className={field.class?.mainclass ?? ""}>
          {!field?.label && (
            <div className={field.class?.labelclass ?? ""}>
              <LabelField
                title={CommonServices.capitalizeLabel(
                  t(field?.title ?? field?.name)
                )}
                mandatory={field?.required}
              />
            </div>
          )}
          <div className={field.class?.feildclass ?? "position-relative"}>
          <InputTextfield
                name= {field?.name}
                type= 'text'
                className= {`form-control shadow-none h-100 mh-38 ${
                  formErrors?.[field?.name] ? "border-danger" : ""
                }`}
                placeholder={t(field?.placeholder)}
                value={formData?.[field?.name]}
                handleChange={(e) => handleInputChange(e, field?.name)}
                handleClick={() => removeError(field?.name)}
                readOnly={field?.disable}
              />
              {formErrors?.hasOwnProperty(field?.name) && (
                <ErrorMessage errorMessage={formErrors?.[field.name]} />
              )}
          </div>
        </div>
      );

    case 'text_input_single_line':
      return (
        <div className={field?.class?.mainclass ?? ""}>
          <LabelField
            title={CommonServices.capitalizeLabel(
              t(field?.title ?? field?.name)
            )}
            mandatory={field.required}
          />
          <div className={field?.class?.feildclass ?? ""}>
            <InputTextAreaField
              name={field?.name}
              className={`form-control shadow-none ${
                formErrors?.[field?.name] ? "border-danger" : ""
              }`}
              handleChange={(e) => handleInputChange(e, field?.name)}
              value={formData[field?.name]}
              handleClick={() => removeError(field?.name)}
              rows={1}
              isDisabled={field?.disable}
            />
          </div>
          {formErrors.hasOwnProperty(field.name) && (
            <ErrorMessage errorMessage={formErrors[field.name]} />
          )}
        </div>
      );

      case "sublevel":
        return(
          <div className={field.class?.mainclass ?? ""}>
            {!field?.label && (
              <div className={field.class?.labelclass ?? ""}>
                <LabelField
                  title={CommonServices.capitalizeLabel(
                    t(field?.title ?? field?.name)
                  )}
                  mandatory={field?.required}
                />
              </div>
            )}
            <div className={field.class?.feildclass ?? "position-relative"}>
            {field?.fields?.length>0 &&<div className="px-4 py-4 form overflow-auto d-flex flex-column gap-3 my-3 w-100">
                    {Object.values(field?.fields).map((value: any) => (
                      <div className={`flex-1 w-100`} key={field?.title}>
                        {
                          renderInput(
                            removeError,
                            value,
                            value?.type == 'date' ? handleDateFormat : handleInputChange,
                            formData,
                            formErrors,
                            ()=>{},
                            handleDateFormat,
                          )
                        }
                        <div className="d-flex">
                        {
                          Object.entries(formData).map(([key1,values])=>{
                            if(value?.name===key1){
                              if (values !== null && typeof values === 'object' && 'file_path' in values && values?.file_path && values?.file_path != '') {
                                return (
                                  <div className="flex-1" key={key1}>
                                      {
                                      value.disable ? <ViewPreviewFile
                                      key={key1}
                                      fileData={values}
                                  /> :
                                    <ViewPreviewFile
                                          key={key1}
                                          fileData={values}
                                          onDeleteFile={(file_path) => removeError(file_path, key1)}
                                    />}
                                  </div>
                              );
                            }
                            }
                            return null
                          })
                        }
                    </div>
                      </div>
                    ))}
                  </div>}
            </div>
          </div>
        );

    default:
      return null;
  }
};
