// src/pages/Newss/context/State.tsx
import { FormMode } from "../../../components/common/CommonEnums";
import Upload from "../../../utils/icons/Upload";
import { NewsState } from "./Interfaces";
import { filterOptions } from "./Utils";

export const initialState: NewsState = {
  mode: FormMode.CREATE,
  tabs: [
    {
      id: "description",
      title: "Description",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "admin",
      title: "Admins",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "moderator",
      title: "Moderators",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "user",
      title: "Users",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "team",
      title: "Teams/Departments",
      showStatus: true,
      error: false,
      draft: false,
    },
  ],
  description: {
    data: {
      title: "",
      description: "",
      status: false,
      header: null,
      thumbnails: null,
      is_birthday_libnews: false,
      disable_notification: false,
      only_admins_can_post: false,
      any_one_can_view: false,
      disable_commenting: false,
      language: "",
    },
    errors: {
      title: "",
      description: "",
      status: "",
      header: "",
      thumbnails: "",
      is_birthday_libnews: "",
      disable_notification: "",
      only_admins_can_post: "",
      any_one_can_view: "",
      disable_commenting: "",
      language: "",
    },
  },
  user: {
    data: {
      user: [],
    },
    errors: {
      user: "",
    },
  },
  team: {
    data: {
      team: [],
    },
    errors: {
      team: "",
    },
  },
  admin: {
    data: {
      admin: [],
    },
    errors: {
      admin: "",
    },
  },
  moderator: {
    data: {
      moderator: [],
    },
    errors: {
      moderator: "",
    },
  },
  currentTab: "description",
  options: {
    User: [],
    Group: [],
    AppLang: [],
  },
  search: "",
  initialCall: true,
};

export const getFormFields = (state: NewsState) => {
  const formFields: any = {
    description: [
      {
        0: {
          name: "title",
          title: "Title",
          type: "text",
          required: true,
          class: {
            // mainclass: '',
            // labelclass: '',
            // feildclass: '',
          },
        },
      },
      {
        0: {
          name: "description",
          title: "Description",
          type: "textarea",
          required: false,
          class: {
            // mainclass: '',
            // feildclass: '',
          },
        },
      },
      {
        0: {
          name: "header",
          title: "Upload header",
          type: "file",
          required: false,
          class: {
            mainclass: "d-flex",
            // labelclass: '',
            // buttonclass: '',
          },
          accept: ".png, .jpg, .jpeg, gif",
          message: "Please upload a file",
          preview: true,
          icon: <Upload />,
          maxSize: 2 * 1024 * 1024,
        },
        1: {
          name: "thumbnails",
          title: "Upload thumbnail",
          type: "file",
          required: false,
          class: {
            mainclass: "d-flex",
            // labelclass: '',
            // buttonclass: '',
          },
          accept: ".png, .jpg, .jpeg, gif",
          message: "Please upload a file",
          preview: true,
          icon: <Upload />,
          maxSize: 2 * 1024 * 1024,
        },
      },
      {
        0: {
          name: "is_birthday_libnews",
          title: "Is birthday libwave",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        1: {
          name: "disable_notification",
          title: "Disable notification",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        2: {
          name: "only_admins_can_post",
          title: "Only admins can post",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
      },
      {
        0: {
          name: "any_one_can_view",
          title: "Any one can view",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        1: {
          name: "status",
          title: "Active",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
        2: {
          name: "disable_commenting",
          title: "Disable commenting",
          type: "switch",
          class: {
            toggleclass: "justify-content-between pe-5",
            // mainclass: '',
            // feildclass: '',
          },
        },
      },
      {
        0: {
          name: "language",
          title: "Language",
          type: "select",
          required: true,
          class: {
            // mainclass: '',
            // feildclass: '',
          },
          options: state["options"]?.AppLang,
        },
      },
    ],
    admin: [
      {
        0: {
          name: "admin",
          title: "Admins",
          type: "listwithselect",
          required: false,
          options: filterOptions(state["options"]?.User, state?.search?.trim()),
          class: {
            // mainclass: '',
            // labelclass: '',
            // buttonclass: '',
          },
        },
      },
    ],
    moderator: [
      {
        0: {
          name: "moderator",
          title: "Moderators",
          type: "listwithselect",
          required: false,
          options: filterOptions(state["options"]?.User, state?.search?.trim()),
          class: {
            // mainclass: '',
            // labelclass: '',
            // buttonclass: '',
          },
        },
      },
    ],
    user: [
      {
        0: {
          name: "user",
          title: "Users",
          type: "listwithselect",
          required: false,
          options: filterOptions(state["options"]?.User, state?.search?.trim()),
          class: {
            // mainclass: '',
            // labelclass: '',
            // buttonclass: '',
          },
        },
      },
    ],
    team: [
      {
        0: {
          name: "team",
          title: "Teams/Departments",
          type: "listwithselect",
          required: false,
          options: filterOptions(
            state["options"]?.Group,
            state?.search?.trim()
          ),
          class: {
            // mainclass: '',
            // labelclass: '',
            // buttonclass: '',
          },
        },
      },
    ],
  };

  return formFields[state.currentTab] || [];
};

export const NewsOptonsPayload = {
  data: [
    {
      model: "Group",
    },
    {
      model: "User",
      filters: { status: [true] },
    },
    {
      model: "AppLang"
    },
  ],
};

export const tableHeaders = [
  { name: "Title", width: 22 },
  { name: "Description", width: 43 },
  { name: "created_at", width: 15, isSort: true },
  { name: "Activity", width: 8 },
];

export const tableValues = ["title", "description", "created_at", "activity"];

export const manageState = {
  data: [],
  postData: {
    limit: 10,
    current_page: 1,
    search: "",
    total_pages: 1,
    status: null,
    order_type: null,
    order: "",
  },
  popup: false,
};

export const viewState = {
  data: [] as any,
  createpost: false,
  isFetching: false,
  postData: {
    limit: 10,
    current_page: 1,
    last: false,
    options: true,
  },
  options: [],
};
