import React, { ChangeEvent, useState } from "react";
import ImageField from "../../components/atoms/ImageField";
import LabelField from "../../components/atoms/LabelField";
import Button from "../../components/atoms/Button";
import LoadingIcon from "../../utils/LoadingIcon";
import { APICALL } from "../../services/ApiServices";
import { generateOtp } from "../../routes/ApiEndpoints";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/types.cjs";
import FormValidation from "../../services/formvalidation/Validation";
import { useNavigate } from "react-router-dom";
import CustomNotify from "../../components/atoms/CustomNotify";

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        phone: '',
        error: '',
        loading: false
    });
    const changeHandler = (
        event: ChangeEvent<HTMLInputElement> | E164Number | undefined,
        field: string
    ) => {
        setState((prevState: any) => ({
            ...prevState,
            [field]: event,
            errors: {
                ...prevState.errors,
                [field]: ''
            }
        }));
    };

    const submitHandler = async (
        event: React.MouseEvent<HTMLButtonElement | HTMLSpanElement, MouseEvent>
    ) => {
        event.preventDefault();
        let error = FormValidation.phoneValidation(state.phone);

        if (error === '') {
            let postData = {
                type: 'otp',
                phone: state.phone,
                resend: false
            }
            const response = await APICALL.service(generateOtp, 'POST', postData);

            if (response.status === 200) {
                localStorage.setItem('phone', state.phone);
                navigate('/reset-password');
            } else {
                CustomNotify({
                    type: "error",
                    message: "The phone number is not registered"
                });
            }
        } else {
            CustomNotify({
                type: "error",
                message: error
            });
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xxl-7 col-xl-6 col-lg-6 vh-100 ps-0 justify-content-center align-items-center vh-100 ps-0 d-none d-md-none d-lg-block">
                    <ImageField
                        altText="Banner"
                        className="w-100 h-100 img-fluid object-fit-cover"
                        value="/static/images/loginImage.jpg"
                    />
                </div>
                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-8 m-auto register-form__fields p-xxl-3 p-xl-0 p-lg-0">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-7 m-auto px-xxl-1 p-lg-0">
                            <div className="text-center mb-5">
                                <ImageField
                                    altText="Logo"
                                    className="text-center logo-width"
                                    value="static/images/logo.svg"
                                />
                            </div>
                            <form className="login-form">
                                <h2 className="text-center mb-4 login-title fw-bold">
                                    {" "}
                                    {"Forgot password"}
                                </h2>
                                <div className="mb-4">
                                    <LabelField
                                        title={"Phone number"}
                                        mandatory={true}
                                    />
                                    <div className='form-control shadow-none'>
                                        <PhoneInput
                                            className='d-flex'
                                            name={'phone'}
                                            required={true}
                                            defaultCountry="BE"
                                            international
                                            initialValueFormat="national"
                                            placeholder="Phone number"
                                            onChange={(e) => changeHandler(e, 'phone')}
                                            value={state.phone}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {!state.loading ? (
                                        <Button
                                            title={"Get OTP"}
                                            type="button"
                                            className="create_button mb-4 w-100 shadow-none rounded-0 text-uppercase"
                                            handleClick={submitHandler}
                                        />
                                    ) : (
                                        <LoadingIcon display="block" classname="dummy" />
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;