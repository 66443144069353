import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ACTION_POSTS, GET_POST } from "../../../../routes/ApiEndpoints";
import { APICALL } from "../../../../services/ApiServices";
import CommentsIcon from "../../../../utils/icons/CommentsIcon";
import Like from "../../../../utils/icons/Like";
import Comments from "./Comments";
import "../CreateNewsOrganism.css";
import ShowMedia from "./ShowMedia";
import ShowPdf from "./ShowPdf";
import ShowSurvey from "./ShowSurvey";
import UserNameAndProfile from "./UserNameAndProfile";
import { ViewPostsProps } from "../../../../pages/news/context/Interfaces";

const SharePost: React.FC <ViewPostsProps>= ({data,fetchPost}) => {
  // {posts:any}
  console.log("post",data?.posts?.posts);
  
  const [post, setPost] = useState<any>(data?.posts);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const postId = searchParams.get("post-id");
  // const newsId = searchParams.get('id');
  // const fetchPost = async () => {
    
  //   if (postId) {
  //     try {
  //       const response = await APICALL.service(GET_POST + "/" + postId +"/" + newsId, "GET");
  //       if (response.status === 200) {
  //         // setPost(response.data);
  //       } else {
  //         console.error("Failed to fetch post");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching post:", error);
  //     }
  //   }
  // };
  
  useEffect(() => {
    // if (postId) {
      // fetchPost();
      setPost(data?.posts);
    // }
  }, [data]);

  console.log("posting",post?.[0].id);
  const handleLike = async () => {
    if (post) {
      const postData = {
        type: "like",
        entity_type: "post",
        entity_id: post?.[0].id,
        status: !post?.[0].has_liked,
      };
      const response = await APICALL.service(ACTION_POSTS, "POST", postData);
      if (response.status === 200) {
        fetchPost(post?.[0].id);
      }
    }
  };
  if (!post) {
    return null;
  }

  return (
    <div className="col-12 col-xl-8 mx-auto mt-3">
      <div key={post?.[0].id} className="border mb-4 p-5">
        <div className="mb-2 d-flex" id={`post-${post?.[0].id}`}>
          <div className="col-12">
            <UserNameAndProfile
              user={post?.[0].post_user}
              created_at={post?.[0].created_at}
            />
          </div>
        </div>
        <div>
          <div
            className="mb-2 text-break"
            dangerouslySetInnerHTML={{ __html: post?.[0].body }}
          ></div>
          {(post?.[0].video?.length > 0 || post?.[0].image?.length > 0) && (
            <ShowMedia videos={post?.[0].video} images={post?.[0].image} />
          )}
          {post?.[0].pdf?.length > 0 && <ShowPdf pdf={post?.[0].pdf} />}
          {post?.[0].survey?.length > 0 && post?.[0].survey[0]?.has_permission && (
            <ShowSurvey
              survey={post?.[0].survey[0]}
              postId={post?.[0].id}
              fetchPost={fetchPost}
            />
          )}
        </div>
        <div className="mt-3">
          <span onClick={handleLike}>
            <Like liked={post?.[0].has_liked} />
          </span>{" "}
          {post?.[0].likes_count} <CommentsIcon /> {post?.[0].comments_count}
        </div>
        <Comments
          postId={post?.[0].id}
          comments={post?.[0].comments}
          fetchPost={fetchPost}
        />
      </div>
    </div>
  );
};

export default SharePost;
