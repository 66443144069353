import { ChangeEvent, useEffect, useState } from "react";
import { manageState, tableHeaders, tableValues } from "./State";
import { useNavigate } from "react-router-dom";
import { APICALL } from "../../../services/ApiServices";
import { createAlerts, manageAlerts } from "../../../routes/ApiEndpoints";
import CustomNotify from "../../atoms/CustomNotify";
import { t } from "../../../services/translation/TranslationUtils";
import Button from "../../atoms/Button";
import ManageFilters from "./ManageFilters";
import TableStructure from "../../atoms/TableStructure";
import Pagination from "../../molecules/Paginations";
import Popup from "../../molecules/Popup";
import CommonServices from "../../../services/CommonService";
import TitleAtom from "../../common/TitleAtom";

const ManageAlertOrganism: React.FC = () => {
  const [state, setState] = useState(manageState);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [state?.current_page, state?.refresh]);

  const fetchData = async (search?: boolean) => {
    let postData: any = {
      limit: state.limit,
      current_page: search ? 1 : state?.current_page,
      initialCall: state?.initialCall,
      ...state?.filters,
      from: CommonServices?.convertToISODate(state?.filters?.from),
      to: CommonServices?.convertToISODate(state?.filters?.to),
    };
    const response = await APICALL.service(manageAlerts, "POST", postData);

    console.log("response", response);
    
    if (response.status === 200) {
      let data: any = {
        data: response?.data?.data,
        totalPages: response?.data?.pagination?.totalPages,
        offset: response?.data?.pagination?.offset,
        totalCount: response?.data?.pagination?.totalCount,
        initialCall: false,
        current_page: search ? 1 : state?.current_page,
      };
      if (response?.data?.options) {
        data = {
          ...data,
          options: response?.data?.options,
        };
      }
      setState((prevState: any) => ({ ...prevState, ...data }));
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    setState((prevState: any) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [field]: e?.target?.value ?? e,
      },
    }));
  };

  const handlePaginationClick = (pageNumber: number) => {
    setState((prevState: any) => ({
      ...prevState,
      current_page: pageNumber,
    }));
  };

  const handleArchivePopUp = (value: any) => {
    handlePopUpState({
      status: value?.activity,
      showpopup: true,
      id: value?.id,
    });
  };
  const handleView = (value: any) => {
    if (value && value.files && value.files.length > 0) {
      const filePath = value.files[0].file_path;
      if (filePath) {
        window.open(filePath, "_blank");
      } else {
        console.error("File path is missing.");
      }
    } else {
      console.error("No files available in the provided value.");
    }
  };

  const handlePopUpState = (values: any, submit?: boolean) => {
    setState((prevState: any) => ({
      ...prevState,
      popup: {
        ...prevState.popup,
        archivepopup: {
          ...prevState?.popup?.archivepopup,
          id: values?.id,
          showpopup: values?.showpopup,
          status: values?.status,
        },
      },
      data: submit
        ? state?.data?.map((item: any) => {
            if (item?.id === state?.popup?.archivepopup?.id) {
              return {
                ...item,
                status: !state?.popup?.archivepopup?.status,
                table: {
                  ...item.table,
                  activity: !state?.popup?.archivepopup?.status,
                },
              };
            } else {
              return item;
            }
          })
        : state?.data,
    }));
  };

  const handleArchive = async () => {
    const postdata = {
      status: state?.popup?.archivepopup?.status ? "false" : "true",
      document_id: state?.popup?.archivepopup?.id,
      change_status: true,
    };
    try {
      const response = await APICALL.service(createAlerts, "POST", postdata);
      CustomNotify({
        type: response?.status === 200 ? "success" : "error",
        message: response.message,
      });
      if (response.status === 200) {
        handlePopUpState({ status: null, showpopup: false, id: null }, true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClear = () => {
    setState((prevState: any) => ({
      ...prevState,
      filters: manageState?.filters,
      current_page: 1,
      refresh: !state?.refresh,
    }));
  };

  return (
    <div>
      <div className="search-bar py-3">
        <div className="row">
          <TitleAtom title={t("Manage alerts")} />
          <div className="col-6">
            <Button
              className="create_button px-3 float-end"
              title={"+ " + t("Create alert")}
              handleClick={() => navigate("/alerts/create")}
            />
          </div>
        </div>
        <ManageFilters
          handleChange={handleChange}
          options={state?.options}
          data={state?.filters}
          handleSubmit={() => fetchData(true)}
          handleClear={handleClear}
        />
      </div>

      <TableStructure
        isAction
        data={state.data}
        headers={tableHeaders}
        values={tableValues}
        handleEdit={(value) => navigate(`/alerts/create?edit=${value?.id}`)}
      />
      {state?.totalPages > 1 && (
        <Pagination
          currentPage={state?.current_page}
          totalPages={state?.totalPages}
          handlePaginationClick={handlePaginationClick}
        />
      )}
      {state?.popup?.archivepopup?.showpopup && (
        <Popup
          body={
            t(
              `Are you sure you want to ${
                state?.popup?.archivepopup?.status ? "deactivate" : "activate"
              }`
            ) + "?"
          }
          yestext={`${
            state?.popup?.archivepopup?.status ? t("Archive") : t("Unarchive")
          }`}
          notext={t("Cancel")}
          submit={handleArchive}
          cancel={() =>
            handlePopUpState({ status: null, showpopup: false, id: null })
          }
        />
      )}
    </div>
  );
};

export default ManageAlertOrganism;
